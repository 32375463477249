import { useCallback, useMemo, useState } from 'react';

import { ArgTable4, ArgTable4Column, ArgTag, ClassNamesFn, ClassValue, DataSorter, useArgNotifications, useClassNames } from 'src/components/basic';
import { Ontology, UniverseFeedLogStatus } from 'src/settings/models/dtoApi';
import { EmptyPane } from 'src/components/common/panes/empty-pane';
import { DateByUser } from 'src/components/common/date-by-user';
import { SynchronizationLogsFilters } from './synchronization-logs-filters';
import { SynchronizationLogsFilter } from './types';
import { UniverseFeedLogStatusRegistry } from './types';
import { messages } from './messages';
import { useUserConfiguration } from 'src/hooks/use-user-configuration';
import { SynchronizationLogsResultsDataProvider, TableUniverseFeedLog } from '../providers/synchronization-logs-data-provider';

import './synchronization-logs.less';

const CLASSNAME = 'settings-synchronization-logs';

const ROW_HEIGHT = 40;

interface SynchronizationsLogsProps {
    className?: ClassValue;
    ontologies: Ontology[];
    initialFilter?: SynchronizationLogsFilter;
}

export function SynchronizationsLogs({
    className,
    ontologies,
    initialFilter = {},
}: SynchronizationsLogsProps) {
    const classNames = useClassNames(CLASSNAME);
    const notifications = useArgNotifications();
    const [dataSorter, setDataSorter] = useUserConfiguration<DataSorter | undefined>('settings.ui.universes.synchronization-logs.sorting', undefined);
    const [filter, setFilter] = useState<SynchronizationLogsFilter>(() => initialFilter);

    const synchronizationLogsProviderErrorHandler = useCallback((error: Error) => {
        notifications.snackError({ message: messages.fetchLogsError }, error);
    }, [notifications]);

    const dataProvider = useMemo(() =>
        new SynchronizationLogsResultsDataProvider(ontologies, synchronizationLogsProviderErrorHandler),
    [ontologies, synchronizationLogsProviderErrorHandler]);

    const columns = buildColumns(classNames);

    return (
        <div className={classNames('&', className)}>
            <SynchronizationLogsFilters
                className={classNames('&-filters')}
                ontologies={ontologies}
                filter={filter}
                onFilterChange={setFilter}
            />
            <div className={classNames('&-body')}>
                <ArgTable4<TableUniverseFeedLog, SynchronizationLogsFilter>
                    className={classNames('&-body-table')}
                    columns={columns}
                    initialItemsCount={10}
                    rowHeight={ROW_HEIGHT}
                    dataProvider={dataProvider}
                    adjustColumns={true}
                    adjustColumnsOnFirstDraw={true}
                    sort={dataSorter}
                    onSortChange={(dataSorter) => setDataSorter(dataSorter, false)}
                    filter={filter}
                    emptyRenderer={() => <EmptyPane
                        message={messages.emptyLogs}
                        className={classNames('&-pane')}
                        size='medium'
                    />}
                />
            </div>
        </div>
    );
}

function buildUniverseFeedLogStatus(universeFeedLog: TableUniverseFeedLog, classNames: ClassNamesFn) {
    const statusDescriptor = UniverseFeedLogStatusRegistry[universeFeedLog.status];
    if (!statusDescriptor) {
        return;
    }

    const { label, className, iconName } = statusDescriptor;

    const ret = (
        <ArgTag
            className={classNames('&-log-status', className)}
            size='large'
            label={label}
            icon={iconName}
        />
    );

    return ret;
}

function buildColumns(classNames: ClassNamesFn) {
    const buildCellClassName = (item?: TableUniverseFeedLog) => {
        return classNames('&-cell', { error: !!(item?.status === UniverseFeedLogStatus.Error) });
    };

    const columns: ArgTable4Column<TableUniverseFeedLog>[] = [
        {
            key: 'ontologyName',
            columnName: 'ontologyName',
            dataIndex: 'ontologyName',
            title: messages.universe,
            cellClassName: (_data, item) => buildCellClassName(item),
            movable: false,
            resizable: true,
            ellipsis: true,
            sortable: false,
            render: function display(_data, item) {
                return item.ontologyName;
            },
        },
        {
            key: 'date',
            columnName: 'date',
            dataIndex: 'date',
            title: messages.executionDate,
            cellClassName: (_data, item) => buildCellClassName(item),
            movable: false,
            resizable: true,
            sortable: true,
            columnSortName: 'Date',
            defaultSortOrder: 'descend',
            render: function display(_data, item) {
                return (
                    <DateByUser date={item.date} />
                );
            },
        },
        {
            key: 'message',
            columnName: 'message',
            dataIndex: 'message',
            title: messages.message,
            cellClassName: (_data, item) => buildCellClassName(item),
            movable: false,
            resizable: true,
            ellipsis: true,
            sortable: false,
            render: function display(_data, item) {
                return item.message;
            },
        },
        {
            key: 'status',
            columnName: 'status',
            dataIndex: 'status',
            title: messages.logLevel,
            cellClassName: (_data, item) => buildCellClassName(item),
            movable: false,
            resizable: true,
            sortable: false,
            render: function display(_data, item) {
                return buildUniverseFeedLogStatus(item, classNames);
            },
        },
    ];

    return columns;
}
