import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { ArgCombo, ArgDateRange, ArgRangePicker, ArgRenderedText, ClassValue, dayjs, useClassNames } from 'src/components/basic';
import { UniverseId } from 'src/exploration/model/universe';
import { Ontology, UniverseFeedLogStatus } from 'src/settings/models/dtoApi';
import { messages } from './messages';
import { SynchronizationLogsFilter, UniverseFeedLogStatusRegistry } from './types';

import './synchronization-logs-filters.less';

const CLASSNAME = 'settings-synchronization-logs-filters';

interface UniverseItem extends ArgComboItem {
    id: UniverseId;
}

interface LogLevelItem extends ArgComboItem {
    iconName: string;
}

interface ArgComboItem {
    id: string;
    label: ArgRenderedText;
}

export interface SynchronizationLogsFiltersProps {
    className?: ClassValue;
    ontologies: Ontology[];
    filter: SynchronizationLogsFilter;
    onFilterChange: React.Dispatch<React.SetStateAction<SynchronizationLogsFilter>>;
}

export function SynchronizationLogsFilters(props: SynchronizationLogsFiltersProps) {
    const {
        className,
        ontologies,
        filter,
        onFilterChange,
    } = props;
    const classNames = useClassNames(CLASSNAME);

    const { universeItems, checkedUniverseItems, handleOnUniverseChange } = useUniversesCombo(ontologies, filter, onFilterChange);
    const { datesValue, handleDatesChange } = useDatesRangePicker(filter, onFilterChange);
    const { logLevelItems, checkedLogLevelItems, handleOnLogLevelChange } = useLogLevelsCombo(filter, onFilterChange);

    return (
        <div className={classNames('&', className)}>
            {universeItems.length > 1 && (
                <ArgCombo<UniverseItem>
                    className={classNames('&-combo-universes')}
                    placeholder={messages.universes}
                    items={universeItems}
                    getItemKey={item => item.id}
                    getItemLabel={item => item.label}
                    cardinality='zeroMany'
                    clearable={true}
                    value={checkedUniverseItems}
                    onChange={handleOnUniverseChange}
                />
            )}
            <ArgRangePicker
                onChange={handleDatesChange}
                allowClear={true}
                includeTime={true}
                showTime={true}
                value={datesValue}
            />
            <ArgCombo<LogLevelItem>
                className={classNames('&-combo-logs-level')}
                placeholder={messages.logLevel}
                type='ghost'
                items={logLevelItems}
                getItemIcon={item => item.iconName}
                getItemKey={item => item.id}
                getItemLabel={item => item.label}
                cardinality='zeroMany'
                clearable={true}
                value={checkedLogLevelItems}
                onChange={handleOnLogLevelChange}
            />
        </div>
    );
}

function useUniversesCombo(
    ontologies: Ontology[],
    filter: SynchronizationLogsFilter,
    onFilterChange: React.Dispatch<React.SetStateAction<SynchronizationLogsFilter>>) {
    const universeItems = useMemo(() => {
        return ontologies
            .flatMap(ontology => {
                return ontology.universeIds.map(universeId => {
                    const universeItem: UniverseItem = {
                        id: universeId,
                        label: ontology.name,
                    };

                    return universeItem;
                });
            });
    }, [ontologies]);

    const checkedUniverseItems = useMemo(() => {
        const _checkedUniverseItems = filter.universeIds?.length
            ? universeItems.filter(universeItem => filter.universeIds?.includes(universeItem.id))
            : [];

        return _checkedUniverseItems;
    }, [filter.universeIds, universeItems]);

    const handleOnUniverseChange = useCallback((checkedUniverseItems: UniverseItem[]) => {
        const checkedUniverseIds = checkedUniverseItems.map(checkedUniverseItem => checkedUniverseItem.id);
        onFilterChange(prev => ({ ...prev, universeIds: checkedUniverseIds }));
    }, [onFilterChange]);

    return {
        universeItems,
        checkedUniverseItems,
        handleOnUniverseChange,
    };
}

function useLogLevelsCombo(
    filter: SynchronizationLogsFilter,
    onFilterChange: React.Dispatch<React.SetStateAction<SynchronizationLogsFilter>>) {
    const handleOnLogLevelChange = useCallback((checkedLogLevels: LogLevelItem[]) => {
        const checkedLogLevelStatuses = checkedLogLevels.map(checkedLogLevel => checkedLogLevel.id as UniverseFeedLogStatus);
        onFilterChange(prev => ({ ...prev, statuses: checkedLogLevelStatuses }));
    }, [onFilterChange]);

    const logLevelItems = useMemo(() => {
        const _logLevelItems = Object.entries(UniverseFeedLogStatusRegistry)
            .map(([key, universeFeedLogStatusDescriptor]) => {
                const logLevelIem: LogLevelItem = {
                    id: key,
                    label: universeFeedLogStatusDescriptor.label,
                    iconName: universeFeedLogStatusDescriptor.iconName,
                };

                return logLevelIem;
            });

        return _logLevelItems;
    }, []);

    const checkedLogLevelItems = useMemo(() => {
        const _checkedLogLevelItems = filter.statuses?.length
            ? logLevelItems.filter(logLevelItem => filter.statuses?.includes(logLevelItem.id as UniverseFeedLogStatus))
            : [];

        return _checkedLogLevelItems;
    }, [filter.statuses, logLevelItems]);

    return {
        logLevelItems,
        checkedLogLevelItems,
        handleOnLogLevelChange,
    };
}

function useDatesRangePicker(
    filter: SynchronizationLogsFilter,
    onFilterChange: Dispatch<SetStateAction<SynchronizationLogsFilter>>) {
    const handleDatesChange = useCallback((dates?: ArgDateRange) => {
        onFilterChange(prev => ({ ...prev, dateFrom: dates?.[0]?.toDate(), dateTo: dates?.[1]?.toDate() }));
    }, [onFilterChange]);

    const datesValue: ArgDateRange = [
        filter.dateFrom ? dayjs(filter.dateFrom) : null,
        filter.dateTo ? dayjs(filter.dateTo) : null,
    ];

    return {
        datesValue,
        handleDatesChange,
    };
}

