import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { compact } from 'lodash';

import { PropertyInformation } from './types';
import { ArgMessageRenderer, ClassNamesFn, ClassValue, useClassNames } from '../../../../../components/basic';
import { UniverseKBFeedingLastSynchronization, UniverseKBLastFeedingStatus } from '../../../../models/dtoApi';
import { KBFeedingPropertyValue } from './kb-feeding-property-value';
import { ResultTag } from '../../../../../components/common/tags/result-tag';
import { buildRangeDatesContent } from './utils';
import { KBFeedingSynchronizationProcessMonitor } from './kb-feeding-process-monitor';
import { messages as commonMessages } from './messages';

import './kb-feeding-monitoring-last-synchronization.less';

const CLASSNAME = 'settings-kb-feeding-monitoring-last-synchronization';

const messages = defineMessages({
    result: {
        id: 'settings.kb-feeding-monitoring-panel.lastSynchronization.result',
        defaultMessage: 'Result',
    },
    errorMessage: {
        id: 'settings.kb-feeding-monitoring-panel.lastSynchronization.errorMessage',
        defaultMessage: 'Error',
    },
});

interface KBFeedingLastSynchronizationProps {
    className?: ClassValue;
    lastSynchronization: UniverseKBFeedingLastSynchronization;
}

export function KBFeedingLastSynchronization({
    className,
    lastSynchronization,
}: KBFeedingLastSynchronizationProps) {
    const classNames = useClassNames(CLASSNAME);

    const propertiesData = useMemo<PropertyInformation[]>(() => getProperyInformation(lastSynchronization, classNames), [lastSynchronization, classNames]);
    const errorMessages = compact([lastSynchronization.object?.error, lastSynchronization.edges?.error]);

    return (
        <div className={classNames('&', className)}>
            {propertiesData.map(property => {
                return <KBFeedingPropertyValue key={property.key} propertyValue={property} />;
            })}
            {lastSynchronization.object && (
                <KBFeedingSynchronizationProcessMonitor title={commonMessages.objects} synchronizationProcess={lastSynchronization.object} />
            )}
            {lastSynchronization.edges && (
                <KBFeedingSynchronizationProcessMonitor title={commonMessages.edges} synchronizationProcess={lastSynchronization.edges} />
            )}
            {!!errorMessages.length && (
                <>
                    <ArgMessageRenderer className={classNames('&-error-field')} message={messages.errorMessage} size='small' />
                    <div className={classNames('&-errors')}>
                        {errorMessages.map((error, index) => (
                            <ArgMessageRenderer
                                key={index}
                                className={classNames('&-error-message')}
                                message={error}
                                size='small'
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

function getProperyInformation(lastSynchronization: UniverseKBFeedingLastSynchronization, classNames: ClassNamesFn) {
    const ret: PropertyInformation[] = [
        {
            key: 'result',
            label: messages.result,
            content: buildUniverseKBFeedingDataLastResult(lastSynchronization, classNames),
        },
        {
            key: 'rangeDates',
            label: commonMessages.rangeDatesTitle,
            content: buildRangeDatesContent(lastSynchronization.rangeDates),
        },
    ];

    return ret;
}

function buildUniverseKBFeedingDataLastResult(lastSynchronization: UniverseKBFeedingLastSynchronization, classNames: ClassNamesFn) {
    const ret = (
        <ResultTag
            className={classNames('&-result')}
            hasFailed={lastSynchronization.synchronizationStatus === UniverseKBLastFeedingStatus.Failed}
        />
    );

    return ret;
}
