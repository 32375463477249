import { MessageDescriptor } from 'react-intl';

import { UniverseFeedLogStatus } from 'src/settings/models/dtoApi';
import { messages } from './messages';
import { UniverseId } from 'src/exploration/model/universe';


export interface SynchronizationLogsFilter {
    universeIds?: UniverseId[];
    statuses?: UniverseFeedLogStatus[];
    dateFrom?: Date;
    dateTo?: Date;
}

interface UniverseFeedLogStatusDescriptor {
    label: MessageDescriptor;
    className: string;
    iconName: string;
}

export const UniverseFeedLogStatusRegistry: Record<UniverseFeedLogStatus, UniverseFeedLogStatusDescriptor> = {
    Error: {
        label: messages.statusError,
        className: 'error',
        iconName: 'icon-close-solid',
    },
    Information: {
        label: messages.statusInformation,
        className: 'information',
        iconName: 'icon-information-solid',
    },
    Warning: {
        label: messages.statusWarning,
        className: 'warning',
        iconName: 'icon-warning',
    },
};
