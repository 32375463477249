import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    fetchLogsError: {
        id: 'settings.synchronization-logs.fetchLogsError',
        defaultMessage: 'Failed to load synchronization logs',
    },
    emptyLogs: {
        id: 'settings.synchronization-logs.emptyLogs',
        defaultMessage: 'No logs',
    },
    statusError: {
        id: 'settings.synchronization-logs.statusError',
        defaultMessage: 'Error',
    },
    statusInformation: {
        id: 'settings.synchronization-logs.statusInformation',
        defaultMessage: 'Information',
    },
    statusWarning: {
        id: 'settings.synchronization-logs.statusWarning',
        defaultMessage: 'Warning',
    },
    universe: {
        id: 'settings.synchronization-logs.universe',
        defaultMessage: 'Universe',
    },
    executionDate: {
        id: 'settings.synchronization-logs.executionDate',
        defaultMessage: 'Execution date',
    },
    message: {
        id: 'settings.synchronization-logs.message',
        defaultMessage: 'Message',
    },
    logLevel: {
        id: 'settings.synchronization-logs.logLevel',
        defaultMessage: 'Log level',
    },
    universes: {
        id: 'settings.synchronization-logs.universes',
        defaultMessage: 'Universes',
    },
});
