
import { ClassValue, useClassNames } from '../../../../../components/basic';
import { UniverseKBFeedingCurrentSynchronization } from '../../../../models/dtoApi';
import { KBFeedingPropertyValue } from './kb-feeding-property-value';
import { KBFeedingSynchronizationProcessMonitor } from './kb-feeding-process-monitor';
import { buildRangeDatesContent } from './utils';
import { messages } from './messages';

import './kb-feeding-monitoring-current-synchronization.less';

const CLASSNAME = 'settings-kb-feeding-monitoring-current-synchronization';

interface KBFeedingCurrentSynchronizationProps {
    className?: ClassValue;
    currentSynchronization: UniverseKBFeedingCurrentSynchronization;
}

export function KBFeedingCurrentSynchronization({
    className,
    currentSynchronization,
}: KBFeedingCurrentSynchronizationProps) {
    const classNames = useClassNames(CLASSNAME);

    const rangeDatesContent = buildRangeDatesContent(currentSynchronization.rangeDates);

    return (
        <div className={classNames('&', className)}>
            <KBFeedingPropertyValue propertyValue={{ label: messages.rangeDatesTitle, content: rangeDatesContent, key: 'rangeDates' }} />
            <KBFeedingSynchronizationProcessMonitor title={messages.objects} synchronizationProcess={currentSynchronization.object} />
            <KBFeedingSynchronizationProcessMonitor title={messages.edges} synchronizationProcess={currentSynchronization.edges} />
        </div>
    );
};
