import { useState, useCallback } from 'react';

import { ClassValue, useClassNames } from 'src/components/basic';
import { ListOntologies } from 'src/settings/models/dtoApi';
import { UniverseTable } from '../components/universe-table/universe-table';
import { TableToolbar } from '../components/table-toolbar/table-toolbar';

import './universes-listing.less';

interface UniversesListingProps {
    className?: ClassValue;
    listOntologies?: ListOntologies;
}

export function UniversesListing({
    className,
    listOntologies,
}: UniversesListingProps){
    const classNames = useClassNames('settings-universes-listing');

    const [search, setSearch] = useState<string>();
    const [universesIdSelected, setUniversesIdSelected] = useState<Set<string>>(() => new Set());

    return (
        <div className={classNames('&', className)}>
            <TableToolbar
                handleSearch={setSearch}
                className={classNames('&-search')}
                universesIdSelected={universesIdSelected}
                listOntologies={listOntologies}
            />
            <div className={classNames('&-table-container')}>
                <UniverseTable
                    search={search}
                    className={classNames('&-table')}
                    universesIdSelected={universesIdSelected}
                    setUniversesIdSelected={setUniversesIdSelected}
                />
            </div>
        </div>
    );
}
