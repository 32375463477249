import { includes, isNumber } from 'lodash';
import Debug from 'debug';

import {
    DataSorter,
    PagedDataProvider,
    ProgressMonitor,
    ProgressMonitorOptions,
    ProgressMonitorsFactoryType,
} from 'src/components/basic';
import { Ontology, UniverseFeedLog } from 'src/settings/models/dtoApi';
import ontologiesConnector, { GetUniversesKbFeedingLogsParams } from 'src/settings/connectors/ontologies-connector';
import { SynchronizationLogsFilter } from '../components/types';


const debug = Debug('settings:universes:synchronizations-logs');

export interface TableUniverseFeedLog extends UniverseFeedLog {
    ontologyName: string;
}

const PAGE_ROW_COUNT = 50;

export class SynchronizationLogsResultsDataProvider extends PagedDataProvider<TableUniverseFeedLog, SynchronizationLogsFilter> {
    #ontologies: Ontology[] = [];

    constructor(
        ontologies: Ontology[],
        errorHandler: (error: Error) => void,
        progressMonitorsFactory?: ProgressMonitorsFactoryType,
        progressMonitorOptions?: ProgressMonitorOptions,
        loadPageTimeout?: number,
    ) {
        super(PAGE_ROW_COUNT, progressMonitorsFactory, progressMonitorOptions, loadPageTimeout, errorHandler);
        this.#ontologies = ontologies;
    }

    protected get totalRowCountMayVary(): boolean {
        return true;
    }

    protected async loadPageContent(
        pageIndex: number,
        pageSize: number,
        filter: SynchronizationLogsFilter | undefined,
        sorter: DataSorter | undefined,
        progressMonitor: ProgressMonitor,
    ) {
        debug('LoadPageContent', 'pageIndex=', pageIndex, 'pageSize=', pageSize, 'filter=', filter);

        const sorting = sorter?.propertySorters?.[0];
        const params: GetUniversesKbFeedingLogsParams = {
            skip: pageIndex,
            top: pageSize,
            sorting: sorting,
            universeIds: filter?.universeIds,
            statuses: filter?.statuses,
            dateFrom: filter?.dateFrom,
            dateTo: filter?.dateTo,
        };

        const result = await ontologiesConnector.getUniversesKbFeedingLogs(
            params,
            progressMonitor,
        );

        const logs = result.data.map(universeFeedLog => mapTableUniverseFeedLog(universeFeedLog, this.#ontologies));
        const count = result.total;

        if (isNumber(count)) {
            debug('LoadPageContent', 'totalRowCount=', count);
            this.totalRowCount = count;
        }

        debug('LoadPageContent', 'totalRowCount=', count, 'results.length=', logs.length);

        return logs;
    }
}

function mapTableUniverseFeedLog(universeFeedLog: UniverseFeedLog, ontologies: Ontology[]) {
    const matchedOntology = ontologies.find(ontology => includes(ontology.universeIds, universeFeedLog.universeId));
    const tableUniverseFeedLog: TableUniverseFeedLog = {
        ...universeFeedLog,
        ontologyName: matchedOntology?.name || universeFeedLog.universeId,
    };

    return tableUniverseFeedLog;
}
