import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { PageHeader } from 'src/settings/common-components/page-header';
import { ArgTab, ArgTabsSubLevel, useClassNames } from 'src/components/basic';
import { ListOntologies } from 'src/settings/models/dtoApi';
import { SynchronizationsLogs } from '../../synchronization-logs/components/synchronization-logs';
import { UniversesListing } from './universes-listing';
import { ExplorationPermissions } from 'src/exploration/model/permissions';
import { useHasPermission } from 'src/contexts/user-permission';
import { computeUniversesManageUrl } from 'src/settings/utils/compute-url';
import { SubTabKey } from './types';
import { SynchronizationLogsFilter } from '../../synchronization-logs/components/types';
import { UniverseId } from 'src/exploration/model/universe';

import './universes-management-view.less';

const messages = defineMessages({
    pageTitle: {
        id: 'settings.universes-management-page.title',
        defaultMessage: 'Universes management',
    },
    fetchingUniverses: {
        id: 'settings.uni.fetch-universes.message',
        defaultMessage: 'Loading universes...',
    },
    fetchingUniversesError: {
        id: 'settings.policy.fetch-universes.error',
        defaultMessage: 'An error occurred while fetching the universes',
    },
    universesListing: {
        id: 'settings.policy.fetch-universes.universesListing',
        defaultMessage: 'Universes list',
    },
    synchronizationLogs: {
        id: 'settings.policy.fetch-universes.synchronizationLogs',
        defaultMessage: 'Synchronization logs',
    },
});


interface UniversesManagementViewProps {
    listOntologies: ListOntologies | undefined;
}

export function UniversesManagementView(props: UniversesManagementViewProps) {
    const {
        listOntologies,
    } = props;
    const classNames = useClassNames('settings-universes-management-view');
    const [searchParams] = useSearchParams();

    const canAccessToFeedLogs = useHasPermission<ExplorationPermissions>('exploration.feed.logging');
    const activeTabKey = (searchParams.get('subTabKey') as SubTabKey | null) || SubTabKey.Universes;
    const filteredUniverseId = (searchParams.get('filteredUniverseId') as UniverseId | null);

    const synchronizationLogsInitialFilter = useMemo(() => {
        const _synchronizationLogsInitialFilter: SynchronizationLogsFilter | undefined = filteredUniverseId
            ? { universeIds: [filteredUniverseId] }
            : undefined;

        return _synchronizationLogsInitialFilter;
    }, [filteredUniverseId]);

    const tabs: ArgTab[] = [
        {
            key: SubTabKey.Universes,
            title: messages.universesListing,
            children: (
                <UniversesListing
                    className={classNames('&-universes-listing')}
                    listOntologies={listOntologies}
                />
            ),
            navigateTo: computeUniversesManageUrl(SubTabKey.Universes),
        },
    ];

    if (canAccessToFeedLogs && listOntologies?.ontologies.length) {
        tabs.push(
            {
                key: SubTabKey.SynchronizationLogs,
                title: messages.synchronizationLogs,
                children: (
                    <SynchronizationsLogs
                        ontologies={listOntologies.ontologies}
                        initialFilter={synchronizationLogsInitialFilter}
                    />
                ),
                navigateTo: computeUniversesManageUrl(SubTabKey.SynchronizationLogs),
            },
        );
    }

    return (
        <div className={classNames('&')}>
            <PageHeader
                className={classNames('&-header')}
                title={messages.pageTitle}
            />
            <ArgTabsSubLevel
                className={classNames('&-tabs')}
                tabs={tabs}
                onChange={() => {}}
                activeTabKey={activeTabKey}
                bodyClassName={classNames('&-tab-body')}
            />
        </div>
    );
}
