import { defineMessages } from 'react-intl';
import { isNil } from 'lodash';

import { ArgMessageRenderer, ArgRenderedText, ClassValue, useClassNames } from '../../../../../components/basic';
import { UniverseKBFeedingSynchronizationProcess } from '../../../../models/dtoApi';
import { SelectionCount } from '../../../../../components/common/controls/selection-count';

import './kb-feeding-process-monitor.less';

const CLASSNAME = 'settings-kb-feeding-process-monitor';

const messages = defineMessages({
    pendingState: {
        id: 'settings.kb-feeding-process-monitor.pendingState',
        defaultMessage: '<i>Pending</i>',
    },
    percentage: {
        id: 'settings.kb-feeding-process-monitor.percentage',
        defaultMessage: '{count} %',
    },
});

interface KBFeedingSynchronizationProcessProps {
    className?: ClassValue;
    title: ArgRenderedText;
    synchronizationProcess?: UniverseKBFeedingSynchronizationProcess;
}

export function KBFeedingSynchronizationProcessMonitor({
    className,
    title,
    synchronizationProcess,
}: KBFeedingSynchronizationProcessProps) {
    const classNames = useClassNames(CLASSNAME);
    const { ratio, error } = synchronizationProcess || { ratio: undefined, error: undefined };

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-title')}>
                <ArgMessageRenderer message={title} size='small' />
            </div>
            <div className={classNames('&-content')}>
                {(!isNil(ratio) && <ProcessProgressBar ratio={ratio} error={error} />)
                    || (<ArgMessageRenderer message={messages.pendingState} size='small' />)}
            </div>
        </div>
    );
}

interface ProcessProgressBarProps {
    ratio: number;
    error?: string;
}

function ProcessProgressBar({
    ratio,
    error,
}: ProcessProgressBarProps) {
    const count = Math.floor(ratio * 100);
    const countMessage = <ArgMessageRenderer message={messages.percentage} messageValues={{ count: count }} size='small' />;

    return (
        <SelectionCount count={countMessage}
                        bgColor={!error ? 'green' : 'red'}
                        countPercent={count}
                        fullWidth={true}
        />
    );
}
