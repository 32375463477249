import { useCallback, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useNavigate } from 'react-router';

import { ArgButton, ArgMenu, ArgMenuItem, useArgModalContext } from 'src/components/basic';
import { EditUniverseModal } from '../edit-universe-modal/edit-universe-modal';
import { DeleteUniverseModal } from '../single-universe-delete-modal/single-universe-delete-modal';
import { useOntologiesContext } from '../../providers/universes';
import { ResetUniverseModal } from 'src/settings/universes/common/reset-universe-modal/reset-universe-modal';
import { UniverseId } from 'src/exploration/model/universe';
import { useHasPermission } from 'src/contexts/user-permission';
import { ExplorationPermissions } from 'src/exploration/model/permissions';
import { computeUniversesManageUrl } from 'src/settings/utils/compute-url';
import { SubTabKey } from '../../views/types';

const messages = defineMessages({
    rename: {
        id: 'settings.universes-management.kebab-menu.rename',
        defaultMessage: 'Rename',
    },
    delete: {
        id: 'settings.universes-management.kebab-menu.delete',
        defaultMessage: 'Delete',
    },
    reset: {
        id: 'settings.universes-management.kebab-menu.reset',
        defaultMessage: 'Reset',
    },
    synchronizationLogs: {
        id: 'settings.universes-management.kebab-menu.synchronizationLogs',
        defaultMessage: 'Synchronization logs',
    },
});

export interface UniverseTableKebabMenuProps {
    ontologyId: string;
    ontologyName: string;
    universeId?: UniverseId;
}

export function UniverseTableKebabMenu(props: UniverseTableKebabMenuProps) {
    const {
        ontologyId,
        ontologyName,
        universeId,
    } = props;

    const modalContext = useArgModalContext();

    const [popoverVisible, setPopoverVisible] = useState<boolean>();
    const { updateOntologies } = useOntologiesContext();
    const canAccessToFeedLogs = useHasPermission<ExplorationPermissions>('exploration.feed.logging');
    const navigate = useNavigate();

    const handleDeleteModal = useCallback(() => {
        setPopoverVisible(false);
        modalContext.open('delete-universe',
            <DeleteUniverseModal
                onClose={() => modalContext.close('delete-universe')}
                ontologyId={ontologyId}
                ontologyName={ontologyName}
                onUniverseDeletion={updateOntologies}
            />,
        );
    }, [modalContext, ontologyId, ontologyName, updateOntologies]);

    const handleEditModal = useCallback(() => {
        setPopoverVisible(false);
        modalContext.open('edit-universe',
            <EditUniverseModal
                onClose={() => modalContext.close('edit-universe')}
                ontologyId={ontologyId}
                ontologyName={ontologyName}
                onUniverseEdition={updateOntologies}
            />);
    }, [modalContext, ontologyId, ontologyName, updateOntologies]);

    const handleResetModal = useCallback(() => {
        if (!universeId) {
            return;
        }
        setPopoverVisible(false);
        modalContext.open('reset-universe',
            <ResetUniverseModal
                onClose={() => modalContext.close('reset-universe')}
                universeId={universeId}
                ontologyName={ontologyName}
                onUniverseReset={updateOntologies}
            />);
    }, [modalContext, ontologyName, universeId, updateOntologies]);

    const handleSynchronizationLogsClick = useCallback(() => {
        const url = computeUniversesManageUrl(SubTabKey.SynchronizationLogs, universeId);
        navigate(url);
    }, [navigate, universeId]);

    const actionsMenu = (
        <ArgMenu data-testid='universe-kebab-button-menu'>
            <ArgMenuItem
                key={messages.rename.id}
                data-testid='rename'
                onClick={handleEditModal}
                label={messages.rename}
            />
            <ArgMenuItem
                key={messages.delete.id}
                data-testid='delete'
                onClick={handleDeleteModal}
                label={messages.delete}
            />
            <ArgMenuItem
                key={messages.reset.id}
                data-testid='reset'
                onClick={handleResetModal}
                label={messages.reset}
            />
            {canAccessToFeedLogs &&
            (
                <ArgMenuItem
                    key={messages.synchronizationLogs.id}
                    data-testid='synchronizationLogs'
                    onClick={handleSynchronizationLogsClick}
                    label={messages.synchronizationLogs}
                />
            )}
        </ArgMenu>
    );

    return (
        <ArgButton
            type='ghost'
            icon='icon-options'
            popover={actionsMenu}
            popoverTrigger='click'
            popoverVisible={popoverVisible}
            onPopoverVisibleChange={setPopoverVisible}
            data-testid='actions-policies-menu'
            popoverPlacement='bottomLeft'
        />
    );
}
