import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    rangeDatesTitle: {
        id: 'settings.kb-feeding-monitoring-panel.rangeDatesTitle',
        defaultMessage: 'Range updates dates',
    },
    objects: {
        id: 'settings.kb-feeding-monitoring-panel.objects',
        defaultMessage: 'Objects',
    },
    edges: {
        id: 'settings.kb-feeding-monitoring-panel.edges',
        defaultMessage: 'Links',
    },
});
