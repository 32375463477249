import { defineMessages } from 'react-intl';
import { useRef, useState } from 'react';

import { ArgCollapse, ArgMessageRenderer, ClassValue, renderText, SubProgressMonitor, useClassNames, useMemoAsync, useSetTimeout } from '../../../../../components/basic';
import { KBFeedingMonitoringMainInfos } from './kb-feeding-monitoring-main-infos';
import { UniverseId } from '../../../../../exploration/model/universe';
import ontologiesConnector from '../../../../connectors/ontologies-connector';
import { Ontology, UniverseKBFeedingData, UniverseKBFeedingStatus } from '../../../../models/dtoApi';
import { ErrorPane } from '../../../../../components/common/panes/error-pane';
import { LoadingPane } from '../../../../../components/common/panes/loading-pane';
import { KBFeedingCurrentSynchronization } from './kb-feeding-monitoring-current-synchronization';
import { KBFeedingLastSynchronization } from './kb-feeding-monitoring-last-synchronization';
import { ArgLink } from '../../../../../components/basic/arg-anchor/arg-link';
import { computeUniversesManageUrl } from 'src/settings/utils/compute-url';
import { SubTabKey } from 'src/settings/universes/management/views/types';
import { useHasPermission } from 'src/contexts/user-permission';
import { ExplorationPermissions } from 'src/exploration/model/permissions';

import './kb-feeding-monitoring-panel.less';

const CLASSNAME = 'settings-kb-feeding-monitoring-panel';
const FORCE_LOADING = false;
const FORCE_ERROR = false;
const POLLING_DELAY_SECONDS = 10;

const messages = defineMessages({
    title: {
        id: 'settings.kb-feeding-monitoring-panel.title',
        defaultMessage: 'Knowledgebase synchronization',
    },
    loadingUniverseKBFeedingData: {
        id: 'settings.kb-feeding-monitoring-panel.loadingUniverseKBFeedingData',
        defaultMessage: 'Loading universe KB monitoring data...',
    },
    currentSynchronizationTitle: {
        id: 'settings.kb-feeding-monitoring-panel.currentSynchronizationTitle',
        defaultMessage: 'Current execution',
    },
    lastSynchronizationTitle: {
        id: 'settings.kb-feeding-monitoring-panel.lastSynchronizationTitle',
        defaultMessage: 'Last execution',
    },
    accessTo: {
        id: 'settings.kb-feeding-monitoring-panel.accessTo',
        defaultMessage: 'Access to',
    },
    logManager: {
        id: 'settings.kb-feeding-monitoring-panel.logManager',
        defaultMessage: 'Log manager',
    },
});

interface KBFeedingMonitoringPanelProps {
    className?: ClassValue;
    universeId: UniverseId;
}
export function KBFeedingMonitoringPanel({
    className,
    universeId,
}: KBFeedingMonitoringPanelProps) {
    const classNames = useClassNames(CLASSNAME);
    const timeoutCallback = useSetTimeout(POLLING_DELAY_SECONDS * 1000);
    const [stateId, setStateId] = useState<number>(0);
    const ontologiesRef = useRef<Ontology[]>();

    const [universeKbFeedingDataResult, universeKbFeedingDataProgressMonitor, universeKbFeedingDataError] =
        useMemoAsync<UniverseKBFeedingData>(async (progressMonitor, prev) => {
            try {
                if (!ontologiesRef.current) {
                    const sub1 = new SubProgressMonitor(progressMonitor, 1);
                    ontologiesRef.current = (await ontologiesConnector.getOntologies(false, sub1)).ontologies;
                }

                const sub2 = new SubProgressMonitor(progressMonitor, 1);
                const universeKbFeedingData = await ontologiesConnector.getUniverseKbFeedingData(universeId, ontologiesRef.current, sub2);

                return universeKbFeedingData;
            } catch (error) {
                if (progressMonitor.isCancelled) {
                    return prev;
                }
                throw error;
            } finally {
                // Polling in order to refresh UI.
                timeoutCallback(() => setStateId(prev => prev + 1));
            }
        }, [universeId, stateId], messages.loadingUniverseKBFeedingData);

    const universeKbFeedingData = universeKbFeedingDataResult;
    const universeKBFeedingCurrentSynchronization = universeKbFeedingDataResult?.current;
    const universeKBFeedingLastSynchronization = universeKbFeedingDataResult?.previous;

    const displayCurrentSynchronization = universeKBFeedingCurrentSynchronization && (universeKbFeedingDataResult.synchronizationStatus === UniverseKBFeedingStatus.Running);
    const canAccessToFeedLogs = useHasPermission<ExplorationPermissions>('exploration.feed.logging');
    const logManagerUrl = canAccessToFeedLogs ? computeUniversesManageUrl(SubTabKey.SynchronizationLogs, universeId) : undefined;

    if (FORCE_ERROR || universeKbFeedingDataError) {
        return (
            <div className={classNames('&', 'error')}>
                <ErrorPane error={universeKbFeedingDataError} />
            </div>
        );
    }

    if (FORCE_LOADING || !universeKbFeedingData) {
        return (
            <div className={classNames('&', 'loading')}>
                <LoadingPane progressMonitor={universeKbFeedingDataProgressMonitor} />
            </div>
        );
    }

    return (
        <div className={classNames('&', className)}>
            <div className={classNames('&-header')}>
                <h3 className={classNames('&-header-title')}>
                    {renderText(messages.title)}
                </h3>
                <KBFeedingMonitoringMainInfos universeKBFeedingData={universeKbFeedingData} />
            </div>
            <div className={classNames('&-body')}>
                {displayCurrentSynchronization && (
                    <ArgCollapse
                        className={classNames('&-body-item')}
                        title={messages.currentSynchronizationTitle}
                        defaultCollapsed={false}
                    >
                        <KBFeedingCurrentSynchronization currentSynchronization={universeKBFeedingCurrentSynchronization} />
                    </ArgCollapse>
                )}
                {universeKBFeedingLastSynchronization && (
                    <ArgCollapse
                        className={classNames('&-body-item')}
                        title={messages.lastSynchronizationTitle}
                        defaultCollapsed={false}
                    >
                        <KBFeedingLastSynchronization lastSynchronization={universeKBFeedingLastSynchronization} />
                    </ArgCollapse>
                )}
            </div>
            {logManagerUrl && (
                <div className={classNames('&-footer')}>
                    <div className={classNames('&-footer-logs-access')}>
                        <div className={classNames('&-footer-logs-access-label')}>
                            <ArgMessageRenderer message={messages.accessTo} size='small' />
                        </div>
                        <ArgLink
                            className={classNames('&-footer-logs-access-link')}
                            icon='icon-code-tags-check'
                            href={logManagerUrl}
                            label={messages.logManager}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

