import { Link } from 'react-router-dom';

import { ArgRenderedIcon, ArgRenderedText, ClassValue, renderIcon, renderText, useClassNames } from '..';

import './arg-link.less';

type ArgLinkIconPlacement = 'left' | 'right';

interface ArgLinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'className'> {
    className?: ClassValue;
    label?: ArgRenderedText;
    icon?: ArgRenderedIcon;
    iconPlacement?: ArgLinkIconPlacement;
}

export function ArgLink({
    className,
    children,
    label,
    icon,
    iconPlacement = 'left',
    href,
    ...otherProps
}: ArgLinkProps) {
    const classNames = useClassNames('arg-link');

    return (
        <Link
            to={href || ''}
            className={classNames('&', className)}
            data-testid='arg-link'
            {...otherProps}>
            <div className={classNames('&-icon-and-label', iconPlacement)}>
                {renderIcon(icon)}
                {renderText(label)}
            </div>
            <div className={classNames('&-children')}>
                {children}
            </div>
        </Link>
    );
}
